import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Welcome from "./details/Welcome";
import Body from "./details/Body";

const ConcertDetails = ({ formatDate, renderBody, concerts }) => {
  const { id } = useParams();
  const concert = concerts.find((obj) => obj.id === id);
  const { t, i18n } = useTranslation();
  const isExpired = new Date(concert.date) < new Date();

  return (
    <>
      <Welcome
        concert={concert}
        t={t}
        formatDate={formatDate}
        isExpired={isExpired}
      />
      <Body
        concert={concert}
        t={t}
        i18n={i18n}
        renderBody={renderBody}
        isExpired={isExpired}
      />
    </>
  );
};

export default ConcertDetails;
